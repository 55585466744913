@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  background: #ffffff;
  height: 100%;
  outline: none !important;
  position: relative;
  /* background: url(./main-container.jpg)no-repeat; */
  background-color: #000210;
}

.dark-mode {
  /* background-color: #202036; */
  color: #FFF;
}

.light-mode {
  background-color: #FFF;
  color: #000210;
}

a {
  color: #FFF;
  text-decoration: none;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

html {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 7px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
  border-radius: 10px;
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

.bg-blue {
  background-color: #DCF1FF;
}

.text-underline {
  text-decoration: underline;
}


img {
  width: 100%;
  height: 100%;
}

.navbar-brand {
  width: 120px;
  height: 120px;
}

/* Typography css end */

/* Color css begin */
.bg-w {
  background: #F5F8FF;
}

.bg-t {
  background: transparent;
}

.fc-b {
  color: #1b9ffe;
}

.bg-b {
  background: #1b9ffe;
}

.fc-w {
  color: #F1F6FE;
}

.bg-black {
  background-color: #000;
}

.bg-gray {
  background-color: #1b9ffe;
}

.bg-blue1 {
  background-color: #d9f7f4;
  border-radius: 10px;
  height: 320px;
}

.bg-loading {
  background: #FBFBFB;
}

.b-r {
  border-radius: 20px;
}

.b-r-50 {
  border-radius: 50%;
}

.b-r-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.b-r-bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.pb-100 {
  padding-bottom: 100px;
}

/* Color css end */

/* Font size css begin */
.fs-toggle {
  font-size: 1px;
}

.fs-60 {
  font-size: 60px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.pt-150 {
  padding-top: 150px !important;
}



/* Font size css end */

/* Navbar css begin */
.navbar {
  padding: 0.1rem;
}

.bg-light {
  background: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  margin-left: 30px;
  color: #1b9ffe;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #FFF;
}

.nav-link:focus {
  color: #1b9ffe !important;
}

.nav-link:hover {
  color: #1b9ffe !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .show>.nav-link {
  color: #1b9ffe;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.navbar .megamenu {
  padding: 1rem;
}

.nav-pills .nav-link {
  color: #1b9ffe;
}

.navbar-toggler {
  background-color: #fff;
}

/* .hero-section-mobile{
  position: relative;
} */




/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  body {
    /* background: url(./main-container.jpg)no-repeat; */
    background-color: #000210;
    background-size: cover;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-left: 30px;
  }

  .dark-white-mobile {
    display: none;
  }

  .hero-section-height {
    min-height: 80vh;
  }

  .colored-button-header:link,
  .colored-button-header:visited {
    padding: 6px 25px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #1b9ffe00 !important;
    border-radius: 25px;
    border: none;
    /* background-color: #1b9ffe !important; */
    background: linear-gradient(-60deg, #80a3f5 40%, #1b9ffe 75%) !important;

    color: #FFF !important;
    overflow: hidden;
    font-size: 16px !important;
    font-weight: 600 !important;
    transition: all .3s ease-in-out;
    outline: none !important;
  }

  .colored-button-header:hover,
  .colored-button-header:active {
    background-color: #FFF !important;
    color: #FFF !important;
    border: 1px solid #1b9ffe00 !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #1b9ffe
  }

  .colored-button-header2:link,
  .colored-button-header2:visited {
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #1b9ffe !important;
    border-radius: 12px;
    border: none;
    color: #1b9ffe !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: all .3s ease-in-out;
    outline: none !important;
  }

  .colored-button-header2:hover,
  .colored-button-header2:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #1b9ffe
  }

  .dark-white-desktop {
    margin-top: -5px !important;
  }

  .privacy-heading {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
  }

  .privacy-heading-1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.23;
  }

  .privacy-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
  }

}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .privacy-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.23;
  }

  .privacy-heading-1 {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.23;
  }

  .privacy-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
  }

  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    margin-top: 10px;
    background-color: #FFF;
    padding-bottom: 70px;
  }

  .dark-white-desktop {
    display: none;
  }

  .colored-button-header:link,
  .colored-button-header:visited {
    padding: 6px 25px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #1b9ffe !important;
    border-radius: 12px;
    border: none;
    background-color: #1b9ffe !important;
    color: #FFF !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: all .3s ease-in-out;
    outline: none !important;
  }

  .colored-button-header:hover,
  .colored-button-header:active {
    background-color: #FFF !important;
    color: #FFF !important;
    background-color: #1b9ffe !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #1b9ffe
  }

  .colored-button-header2:link,
  .colored-button-header2:visited {
    padding: 8px 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #1b9ffe !important;
    border-radius: 12px;
    border: none;
    color: #1b9ffe !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: all .3s ease-in-out;
    outline: none !important;
  }

  .colored-button-header2:hover,
  .colored-button-header2:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #1b9ffe
  }
}

/* Navbar css end */

/* Hero-section css start */

.colored-button-header3:link,
.colored-button-header3:visited {
  padding: 10px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #1b9ffe !important;
  border-radius: 12px;
  border: none;
  background-color: #FFF !important;

  color: #1b9ffe !important;
  overflow: hidden;
  font-size: 16px !important;
  font-weight: 600 !important;
  transition: all .3s ease-in-out;
  outline: none !important;
}

.colored-button-header3:hover,
.colored-button-header3:active {
  background-color: #FFF !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px 0 #1b9ffe
}

.header-text {
  font-size: 20px;
  font-weight: 500
}

.content-text {
  font-size: 16px;
  font-weight: 500
}

.attributions-text {
  font-size: 16px;
  font-weight: 400;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.footer-cta2 {
  border-top: 1px solid #373636;
}

.footer-cta3 {
  border: 1px solid #373636;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.dark-mode .copyright-footer-text {
  color: #FFF !important;
  font-size: 16px;
  font-weight: 500;
}

.light-mode .copyright-footer-text {
  color: #000210 !important;
  font-size: 16px;
  font-weight: 500;
}


.copyright-area {
  padding: 10px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.copyright-text p a {
  color: #1b9ffe;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #1b9ffe;
}

.footer-menu li a {
  font-size: 14px;
}

/* Start Nav Styles */
.navbar-two {
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.navbar .nav-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navbar .nav-menu li {
  position: relative;
  min-width: 200px;
  text-align: right;
}

.navbar .nav-menu li .dot {
  display: block;
  color: #fff;
  padding: 5px 0;
}

.navbar .nav-menu li .dot::before,
.navbar .nav-menu li .dot::after {
  display: block;
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  transition: all 0.3s ease;
}

.navbar .nav-menu li .dot::before {
  width: 15px;
  height: 15px;
  right: 0;
  border: 2px solid #1b9ffe;
  transform: translateY(-50%);
}

.navbar .nav-menu li .dot::after {
  width: 25px;
  height: 25px;
  border: 2px solid #1b9ffe;
  right: -5px;
  transform: translateY(-50%) scale(0);
}

.navbar .nav-menu li .dot.active::before,
.navbar .nav-menu li:hover .dot::before {
  background: #1b9ffe;
  border-color: #1b9ffe;
}

.navbar .nav-menu li .dot.active::after,
.navbar .nav-menu li:hover .dot::after {
  transform: translateY(-50%) scale(1);
}

.navbar .nav-menu li .dot span {
  display: inline-block;
  opacity: 0;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  background-color: #1b9ffe;
  padding: 10px 20px;
  border-radius: 3px;
  margin-right: 30px;
  transform: translateX(20px);
  transition: all 0.3s ease;
}

.navbar .nav-menu li .dot span::before {
  display: block;
  position: absolute;
  content: "";
  border-left: 7px solid #1b9ffe;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  top: 50%;
  transform: translate(7px, -50%);
  right: 0;
  transition: all 0.3s ease;
}


/* ============ mobile view ============ */
@media(max-width: 991px) {
  .navbar .nav-menu li .dot span {
    font-size: 12px;
  }

  .navbar .nav-menu li .dot span::before {
    font-size: 12px;
  }

  .navbar-two {
    top: 50%;
    right: 15px;
  }
}

.navbar .nav-menu li .dot.active span,
.navbar .nav-menu li:hover .dot span {
  transform: translateX(0px);
  opacity: 1;
}

/* End Nav Styles */
.download-background {
  background-color: #dcf1ff;
  border-radius: 20px;
}



.one {
  background-image: -webkit-linear-gradient(30deg, transparent 60%, #DCF1FF 60%);
  min-height: 500px;
}

.typewriter {
  font-family: monospace;
  line-height: 1.5;
}

.top-video {
  height: 100%;
  width: 100%;
}

.home-carousel-images {
  width: 80px;
  height: 80px;
}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .home-carousel-images {
    width: 40px;
    height: 40px;
  }
}


.carousel-control-next-icon {
  background-image: url("./images/50621.png");
}

.carousel-control-prev-icon {
  background-image: url("./images/25257.png");
}


.container th h1 {
  font-weight: bold;
  font-size: 1em;
  color: #000;
}

.container td {
  font-weight: normal;
  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
  -moz-box-shadow: 0 2px 2px -2px #0E1119;
  box-shadow: 0 2px 2px -2px #0E1119;
}



.container td,
.container th {
  padding-bottom: 1%;
  padding-top: 1%;
  padding-left: 1%;
}



.container th {
  background-color: #DCF1FF;
}

.container td:first-child {
  color: #1b9ffe;
}

.container tr:hover {
  background-color: #464A52;
  -webkit-box-shadow: 0 6px 6px -6px #0E1119;
  -moz-box-shadow: 0 6px 6px -6px #0E1119;
  box-shadow: 0 6px 6px -6px #0E1119;
}

.container td:hover {
  background-color: #DCF1FF;
  color: #403E10;
  font-weight: bold;

  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: line;
}

@media (max-width: 800px) {

  .container td:nth-child(4),
  .container th:nth-child(4) {
    display: none;
  }
}

tbody tr td img {
  width: 50px;
}

.timeline {
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5rem;
  margin: 0 auto 1rem auto;
  overflow: hidden;
}

.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  border-right: 2px dashed #4b546f;
  height: 100%;
  display: block;
}

.timeline-row {
  padding-left: 50%;
  position: relative;
  margin-bottom: 30px;
}

.timeline-row .timeline-time {
  position: absolute;
  right: 50%;
  top: 15px;
  text-align: right;
  margin-right: 20px;
  color: #bcd0f7;
  font-size: 1.5rem;
}

.timeline-row .timeline-time small {
  display: block;
  font-size: 0.8rem;
}

.timeline-row .timeline-content {
  position: relative;
  padding: 20px 30px;
  background: #DCF1FF;
  color: #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.timeline-row .timeline-content:after {
  content: "";
  position: absolute;
  top: 20px;
  height: 16px;
  width: 16px;
  background: #1b9ffe;
}

.timeline-row .timeline-content:before {
  content: "";
  position: absolute;
  top: 20px;
  right: -49px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  z-index: 10;
  border: 2px dashed #4b546f;
}

.timeline-row .timeline-content h4 {
  margin: 0 0 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 150%;
}

.timeline-row .timeline-content p {
  margin-bottom: 30px;
  line-height: 150%;
}

.timeline-row:nth-child(even) .timeline-content {
  margin-left: 40px;
  text-align: left;
}

.timeline-row:nth-child(even) .timeline-content:after {
  left: -8px;
  right: initial;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-135deg);
}

.timeline-row:nth-child(even) .timeline-content:before {
  left: -52px;
  right: initial;
}

.timeline-row:nth-child(odd) {
  padding-left: 0;
  padding-right: 50%;
}

.timeline-row:nth-child(odd) .timeline-time {
  right: auto;
  left: 50%;
  text-align: left;
  margin-right: 0;
  margin-left: 20px;
}

.timeline-row:nth-child(odd) .timeline-content {
  margin-right: 40px;
}

.timeline-row:nth-child(odd) .timeline-content:after {
  right: -8px;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

@media (max-width: 992px) {
  .timeline {
    padding: 15px;
  }

  .timeline:after {
    border: 0;
  }

  .timeline .timeline-row:nth-child(odd) {
    padding: 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content {
    margin: 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content:before {
    display: none;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content:after {
    display: none;
  }

  .timeline .timeline-row:nth-child(even) {
    padding: 0;
  }

  .timeline .timeline-row:nth-child(even) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    text-align: left;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content {
    margin: 0;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content:before {
    display: none;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content:after {
    display: none;
  }
}

.copyright-text ul {
  list-style-type: none;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {}

/* ============ mobile view ============ */
@media(max-width: 991px) {}

.footer-icons {
  font-size: 56px;
  color: #1b9ffe;
  margin-left: 10px;
  transition: transform .2s;
  /* Animation */
  border: 1px solid #1b9ffe;
  border-radius: 12px;
  padding: 10px;
}

.footer-icons:hover {
  transform: scale(1.3);
}

.err_page {
  width: 100%;
  height: 80%;
  margin: 4% auto;
  text-align: center;
  display: flex;
  align-items: center;
}

.err_page_right {
  width: 100%;
}

.err_page_left {
  width: 100%;
}

.err_page h1 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 70pt;
  margin: 0;
  color: #1b9ffe;
}

.err_page h4 {
  color: #1b9ffe;
  font-size: 14pt;
}

.err_page p {
  font-size: 14pt;
  color: #737373;
}

.err_btn {
  background: #fff;
  border: 2px solid #1b9ffe;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 13pt;
  transition: background 0.5s;
}

.err_btn:hover {
  background: #1b9ffe;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.credit {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 800px) {
  .err_page {
    flex-direction: column;
  }

  .err_page_left img {
    width: 250px;
    height: auto;
  }

  .err_page h4 {
    margin: 0;
  }
}

.hover01 img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.hover01 img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.axie img {
  width: 40px;
  height: 40px;
}

.hidden-content {
  color: transparent;
}

.extension-button-nav {

  background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.extension-button-nav:hover {
  background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
  color: #fff !important;
}

.googleplay-button-nav {
  color: #1b9ffe !important;
  background: #FFF;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #1b9ffe;
}

.googleplay-button-nav:hover {
  color: #FFF !important;
  background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;

}

.nav-items-text {
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
}

.nav-items-text:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #FFF !important;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-items-text:hover:after {
  width: 100%;
  left: 0;
}


/* .text-gradient {
  background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
} */



.image-toy-large {
  width: 40px !important;
  height: 40px !important;
}

.d-wallet-third-section h5 {
  font-size: 14px;
}

.d-wallet-third-section p {
  font-size: 12px;
}


.d-wallet-fourth-section h5 {
  font-family: "Raleway", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  padding-left: 10px;

}

.d-wallet-fourth-section p {
  font-family: "Lato", Sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  padding-left: 10px;

}

@font-face {
  font-family: "Redig";
  src: local("Redig"),
    url("./images/OTF/Redig-Regular.otf") format("truetype");
}


/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .d-wallet-fourth-section-image img {
    width: 107px;
    height: 124px;
    margin-top: -60px;
    padding-bottom: 20px;
  }

  .d-wallet-six-section-image img {
    width: 84px;
    height: 83px;
    margin-top: -50px;
  }

  .star-image {
    width: 40px !important;
    margin-bottom: -10px;
    margin-left: -20px;
  }

  .hero-section-top-text-1 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.5rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-gradient-text-1 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.5rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-gradient-text-2 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-top-text-2 {
    font-size: 24px;
    letter-spacing: 1px;
    font-family: sans-serif;

  }

  .hero-section-top-text-3 {
    font-size: 20px;
    letter-spacing: 1px;
    font-family: sans-serif;
  }

  .hero-section-styling {
    padding-top: 100px;
  }

}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .star-image {
    width: 40px !important;
  }

  .d-wallet-six-section-image img {
    width: 54px;
    height: 53px;
    margin-top: -20px;
  }

  .d-wallet-fourth-section-image img {
    width: 107px;
    height: 100px;
    margin-top: -30px;
    padding-bottom: 20px;
  }

  .hero-section-top-text-1 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-gradient-text-1 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-gradient-text-2 {
    background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
    font-family: "Redig";
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem !important;
    letter-spacing: .01em !important;
    font-weight: 400;
  }

  .hero-section-top-text-2 {
    font-size: 24px;
    letter-spacing: 1px;
    font-family: sans-serif;

  }

  .hero-section-top-text-3 {
    font-size: 20px;
    letter-spacing: 1px;
    font-family: sans-serif;
  }

  .hero-section-styling {
    padding-top: 130px;
  }
}


.button-container-2 {
  position: relative;
  width: 180px;
  height: 50px;
  margin: auto;
  overflow: hidden;
  border: 1px solid #21dcfd;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  transition: 0.5s;
  letter-spacing: 2px;
  border-radius: 25px;
  text-transform: uppercase;
}

.button-container-2 .mask-button {
  width: 101%;
  height: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(108.46deg, #21dcfd 12.51%, #80a3f5 51.13%, #4286f4 87.49%);
  -webkit-mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  -webkit-mask-size: 3000% 100%;
  mask-size: 3000% 100%;
  border: none;
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(29) forwards;
  animation: ani2 0.7s steps(29) forwards;
}

.button-container-2 .mask-button:hover {
  -webkit-animation: ani 0.7s steps(29) forwards;
  animation: ani 0.7s steps(29) forwards;
}

.mas {
  position: absolute;
  color: #21dcfd !important;
  text-align: center;
  width: 101%;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  position: absolute;
  font-size: 14px;
  margin-top: 14px;
  overflow: hidden;
  font-weight: bold;
}

@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

.qrcode-text {
  color: #FFF;
  font-size: 12px;
}

.tooltip-image {
  width: 200px;
  height: 200px;
}

.qrcode-background-color {
  color: #fff;
  background: #FFF !important;
  border: 1px solid #FFF !important;
  opacity: 1 !important;
}

.qrcode-icons {
  color: #4286f4;
  font-size: 55px;
  background-color: #FFF;
  padding: 10px;
  border-radius: 12px;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .extensionmulti-card .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    background: linear-gradient(108.46deg, #21dcfd31 12.51%, #80a3f554 51.13%, #4286f43a 87.49%);
    border-radius: 0.25rem;
    border: 2px solid #35394510;
    background-color: #141416;
    box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
    border-radius: 12px;
    height: 100%;
    padding: 30px 10px;
  }
  
  
  .extensionmulti-card .card-title {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
      border-radius: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
  }
  .extensionmulti-button{
    background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    padding: 8px;
    border-radius: 20px;
    text-transform: uppercase;
    border: 0px;
  }
  .extensionmulti-card .card-title {
    color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}
  .extensionmulti-text-1 {
    font-size: 2.8rem;
    font-family: 'Rubik', sans-serif !important;
    letter-spacing: 1px;
    width: 75%;
    margin: auto;
    text-transform: capitalize;
    margin-bottom: 20px !important;
    color: #FFF;
  }
  .extensionmulti-text-2 {
    font-size: 2.1rem;
    font-family: 'Rubik', sans-serif !important;
    letter-spacing: 1px;
    width: 75%;
    margin: auto;
    text-transform: capitalize;
    margin-bottom: 20px !important;
    color: #FFF;
  }
  .extensionmulti-card .card-img-top {
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .hero-section-text-style {
    font-size: 40px !important;
  }

  /* .bottom-extensive-image{
      width: 900px !important;
       linear-gradient(-60deg, #5362abd0 40%, #7052a3d2  75%)
    } */

  .bg-hero {
    height: 100vh;
    background:
      /* linear-gradient(-60deg, #4f2a5a 40%, #4f2a5a  75%), */
      url('./images/background-sample.gif')center center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    /* background-position: top center; */
    /* height: 50% !important; */
    /* position: absolute; */
  }


  .hero-section-image {
    width: 80% !important;
  }

  .d-wallet-second-section h5 {
    height: 104px;
    align-items: center;
    font-size: 1.6rem !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
    line-height: 40px;
  }

  .d-wallet-second-section p {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: sans-serif;
    padding-left: 20px;
    padding-right: 20px;
  }
.iframe-video{
  height: 500px;
}

}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .iframe-video{
    height: 300px;
  }
  .extensionmulti-card .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    background: linear-gradient(108.46deg, #21dcfd31 12.51%, #80a3f554 51.13%, #4286f43a 87.49%);
    border-radius: 0.25rem;
    border: 2px solid #35394510;
    background-color: #141416;
    box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
    border-radius: 12px;
    height: 100%;
    padding: 23px 4px;
  }
  
  
  .extensionmulti-card .card-title {
      color: #fff !important;
      font-size: 12px;
      font-weight: 400;
      border-radius: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
  }
  .extensionmulti-button{
    background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
    color: #fff !important;
    font-size: 10px;
    font-weight: 400;
    cursor: pointer;
    padding: 8px;
    border-radius: 20px;
    text-transform: uppercase;
    border: 0px;
  }


  .extensionmulti-text-1 {
    font-size: 1.2rem;
    font-family: 'Rubik', sans-serif !important;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 20px !important;
    color: #FFF;
  }
  .extensionmulti-text-2 {
    font-size: 1rem;
    font-family: 'Rubik', sans-serif !important;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 20px !important;
    color: #FFF;
  }
  .extensionmulti-card .card-img-top {
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .bg-hero {
    height: 100vh;
    background:
      /* linear-gradient(-60deg, #4f2a5a 40%, #4f2a5a  75%), */
      url('./images/background-sample.gif')center center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    /* background-position: top center; */
    /* height: 75%; */
    width: 100%;
    /* position: absolute; */
  }


  .hero-section-image {
    width: 80%;
  }

  .d-wallet-second-section h5 {
    font-size: 1.1rem !important;
    margin-top: 30px;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .d-wallet-second-section p {
    font-size: 14px !important;
    line-height: 1.5rem !important;
    letter-spacing: 1px !important;
    padding-left: 20px;
    padding-right: 20px;
  }


}

.d-wallet-second-section,
.d-wallet-third-section,
.d-wallet-fourth-section {
  overflow: hidden;
}

.d-wallet-second-section .four-card-styling {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent !important;
  background-clip: border-box;

  border-radius: 0.25rem;
  border: 2px solid #3539453a;
  background-color: #141416;
  box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
  border-radius: 12px;
}

.d-wallet-second-section img {
  width: 150px;
  height: 150px;
  padding-top: 20px;
  padding-left: 20px;
}

.d-wallet-fourth-section {
  overflow: hidden;
}

.d-wallet-fourth-section .four-card-styling {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent !important;
  background-clip: border-box;
  background: linear-gradient(108.46deg, #21dcfd31 12.51%, #80a3f554 51.13%, #4286f43a 87.49%);

  border-radius: 0.25rem;
  border: 2px solid #35394510;
  background-color: #141416;
  box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
  border-radius: 12px;
}

.four-card-styling {

  background: linear-gradient(110.29deg, rgba(196, 196, 196, 0.2) -7.11%, rgba(71, 71, 71, 0.084) 65.77%, rgba(183, 183, 183, 0.2) 106.66%);
}

.four-card-styling:hover {


  background: linear-gradient(108.46deg, #21dcfd31 12.51%, #80a3f554 51.13%, #4286f43a 87.49%);
  border-image: linear-gradient(108.46deg, #5ee6cfd5 12.51%, #bfc6ffd5 51.13%, #5ee6cfd5 87.49%);
  border-image-slice: 1;
  border: solid 2px #5ee6cfd5;
  border-radius: 12px;

  /* border-image: 1px solid linear-gradient(108.46deg, #5ee6cfda 12.51%, #bfc6ffd2 51.13%, #ffb969d8 87.49%); */
}

.css-catpii-MuiSwitch-root .MuiSwitch-thumb {
  background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
  width: 32px;
  height: 32px;
}

@media (min-width: 1025px) and (max-width: 1800px) {
  .hero-section-image {
    width: 60% !important;
  }
}

/* ===============================POPUP============================= */

#thover {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: .6
}

#tpopup {
  position: absolute;
  width: 60%;
  height: 60%;

  left: 50%;
  top: 50%;
  border-radius: 5px;
  padding: 60px 0;
  margin-left: -320px;
  /* width/2 + padding-left */
  margin-top: -150px;
  /* height/2 + padding-top */
  text-align: center;
  box-shadow: 0 0 10px 0 #000;
}

#tclose {
  position: absolute;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  right: -15px;
  top: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 10px;
  font-weight: bolder;
  font-family: 'Arial Black', Arial, sans-serif;
  cursor: pointer;
  box-shadow: 0 0 10px 0 #000;
}

.modal-dialog-effect {
  background: rgba(27, 159, 254, 0.15);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-radius: 10px;

}

.modal-dialog-effect .btn-close {
  background: url("../src/images/cross.white.svg");
  opacity: 0.8;
  margin-right: 15px !important;
  margin-top: 15px !important;
  box-shadow: none !important;
}




.extensionmulti-video{
  margin: auto !important;
  text-align: auto !important;
}
.extensionmulti-button-2{
  background: linear-gradient(-60deg, #80a3f5 40%, #4286f4 75%) !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  padding: 14px 24px !important;
  border-radius: 25px;
  text-transform: uppercase;
  border: 0px;
}
